<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget(v-if="filters")
      .widget-header
        h1.title Postback Log
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'postbackLog'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group
              label Secret
              input.form-control(type="text", placeholder="Secret", v-model="filters.secret")
            ov-date-time-picker(v-model="filters.datePicker")
            .form-group
              label Search
              input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
            select-advertiser(v-model="filters.advertiser_id")
            select-offer(v-model="filters.offer_id")
            select-publisher(v-model="filters.publisher_id")
            select-placement(v-model="filters.placement_id")
            .form-group
              label Type
              select.form-control(v-model="filters.type")
                option(v-for="o in typeOptions", :value="o.v") {{ o.t }}
            .form-group
              label MMP Account
              select.form-control(v-model="filters.mmp_account_id")
                option(value="") All
                option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}
            .form-group
              label Format
              select.form-control(v-model="filters.format")
                option(v-for="o in formatOptions", :value="o.v") {{ o.t }}
            .form-group
              label Status
              select.form-control(v-model="filters.status")
                option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
            .form-group
              label AP Mode
              select.form-control(v-model="filters.advanced_privacy")
                option(value="-1") All
                option(value="1") Advanced Privacy Only
                option(value="0") Non-AP Only

            //.form-group.no-label
              label.form-check-label
                input.form-check-input(type="checkbox", v-model="filters.only_complex")
                | Only complex
            .form-group.no-label
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download
                    | Export

        p.clearfix &nbsp;

        .ov-table-wrapper
          paginate(:paginator="paginate", @update-page-size="loadData")
          table.table.table-bordered
            thead
              tr
                th ID
                th Type
                th Format
                th Status
                th Error
                th Request URL
                th IP / ClickID / Secret
                th Params
                th Received at
                //th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="9") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td {{ typeLabel[r.type] }}
                td
                  span(v-if="!r.format") API
                  mmp-icon(v-else, :mmp="r.format")
                td
                  .badge.badge-default(v-if="r.status===0") Pending
                  .badge.badge-success(v-if="r.status===1") Done
                  .badge.badge-warning(v-if="r.status===2") Retry
                  .badge.badge-danger(v-if="r.status===3") Error
                  .badge.badge-danger(v-if="r.status===5") Invalid Secret
                td {{ r.error_string }}
                td
                  div.request-string {{ r.request_string }}
                td
                  div.ip IP: {{ r.ip }}
                  div.secret Secret: {{ r.secret }}
                  div.raw-clickid RawClickID: {{ r.raw_click_id }}
                td
                  pre.json-obj {{ JSON.stringify(r.params, null, 2) }}
                td {{ r.created_at }}
                //td.actions
                  button.btn.btn-sm.btn-secondary(type="button", @click="retry(r)")
                    i.la.la-refresh
                  //a.btn.btn-sm.btn-secondary(href="")
                    i.la.la-trash
          paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<style lang="scss">
.request-string {
  width: 300px;
  font-family: 'Courier New';
  font-size: 11px;
  word-break: break-all;
}
.raw-clickid {
  width: 300px;
  font-family: 'Courier New';
  font-size: 11px;
  word-break: break-all;
}
.ip {
  margin-bottom: 10px;
}
.secret {
  margin-bottom: 10px;
  margin-bottom: 10px;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'PostbackLog',
  async beforeRouteEnter(to, from, next) {
    let mmpAccountOptions = await Vue.ovData.mmpAccount.getOptions(true);

    let selectedAdvertiserOptions = await Vue.ovData.advertiser.getSelectedOptions(
      to.query.advertiser_id,
    );
    let selectedPublisherOptions = await Vue.ovData.publisher.getSelectedOptions(
      to.query.publisher_id,
    );
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
      to.query.placement_id,
    );

    next(async (vm) => {
      vm.mmpAccountOptions = mmpAccountOptions;
      vm.filters.offer_id = selectedOfferOptions;
      vm.filters.publisher_id = selectedPublisherOptions;
      vm.filters.placement_id = selectedPlacementOptions;
      vm.filters.advertiser_id = selectedAdvertiserOptions;
      vm.loadData();
      return vm;
    });
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  data() {
    const defaultFilters = {
      status: -1,
      type: 'all',
      format: 'all',
      secret: '',
      keyword: '',
      // only_complex: false,
      offer_id: [],
      placement_id: [],
      publisher_id: [],
      advertiser_id: [],
      mmp_account_id: '',
      advanced_privacy: -1,
      datePicker: {
        timezone: 0,
        dateRange: {
          startDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          endDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        },
      },
    }
    return {
      busy: false,
      areFiltersOpened: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      mmpAccountOptions: [],
      defaultFilters,
      filters: { ...defaultFilters },
      statusOptions: [
        { v: -1, t: 'All' },
        { v: 0, t: 'Pending' },
        { v: 1, t: 'Success' },
        { v: 2, t: 'Retry' },
        { v: 3, t: 'Error' },
        // {v: 4, t: '4 - '},
        { v: 5, t: 'Invalid Secret' },
      ],
      typeOptions: [
        { v: 'all', t: 'All' },
        // {v: 'conversion', t: 'Conversions Only'},
        { v: 'conversion', t: 'Installs' },
        { v: 'event', t: 'Events' },
        { v: 'reject', t: 'Rejected' },
      ],
      typeLabel: {
        conversion: 'Install',
        event: 'Event',
        reject: 'Rejected',
      },
      formatOptions: [
        { v: 'all', t: 'All' },
        { v: 'appsflyer', t: 'Appsflyer' },
        { v: 'kochava', t: 'Kochava' },
        { v: 'adjust', t: 'Adjust' },
        { v: 'singular', t: 'Singular' },
        { v: 'branch', t: 'Branch' },
        { v: 'tenjin', t: 'Tenjin' },
        { v: 'nommp', t: 'None MMP' },
      ],
      records: [],
    };
  },
  methods: {
    async retry(record) {
      let data = {
        id: record.id,
      };
      try {
        await this.$ovReq.post('postbackLog/retry', data);
        this.loadData();
      } catch (e) {
        console.error(e);
      }
    },

    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        type: this.filters.type,
        format: this.filters.format,
        mmp_account_id: this.filters.mmp_account_id,
        advanced_privacy: +this.filters.advanced_privacy,
        status: +this.filters.status,
        secret: this.filters.secret,
        // only_complex: this.filters.only_complex ? 1 : 0,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),
        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };
      try {
        let resp = await this.$ovReq.get('postbackLog/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
